import * as React from "react";
import "./Home.css";

function Home({ setPlayGame }) {
  return (
    <div class="Home">
      <div class="Home__header">
        <h1>Chess is Racist</h1>
        <p>but it doesn't have to be...</p>
        <button onClick={() => setPlayGame(true)}>Play Now</button>
      </div>
    </div>
  );
}

export default Home;
