const piecesArray = [
  [0, 0, "bq"],
  [0, 1, "bb"],
  [0, 2, "bn"],
  [0, 3, "bn"],
  [0, 4, "bn"],
  [0, 5, "bn"],
  [0, 6, "bb"],
  [0, 7, "bk"],
  [1, 0, "br"],
  [1, 1, "br"],
  [1, 0, "br"],
  [1, 1, "br"],
  [2, 0, "bp"],
  [2, 1, "bp"],
  [2, 7, "bp"],
  [2, 6, "bp"],
  [1, 2, "bp"],
  [1, 3, "bp"],
  [1, 4, "bp"],
  [1, 5, "bp"],
  [1, 6, "br"],
  [1, 7, "br"],
  [5, 7, "wp"],
  [5, 6, "wp"],
  [5, 0, "wp"],
  [5, 1, "wp"],
  [6, 0, "wr"],
  [6, 1, "wr"],
  [6, 2, "wp"],
  [6, 3, "wp"],
  [6, 4, "wp"],
  [6, 5, "wp"],
  [6, 6, "wr"],
  [6, 7, "wr"],
  [7, 0, "wq"],
  [7, 1, "wb"],
  [7, 2, "wn"],
  [7, 3, "wn"],
  [7, 4, "wn"],
  [7, 5, "wn"],
  [7, 6, "wb"],
  [7, 7, "wk"],
];
export default piecesArray;
