import w_p from "./images/WhitePawn.png";
import b_p from "./images/BlackPawn.png";
import w_b from "./images/WhiteBishop.png";
import b_b from "./images/BlackBishop.png";
import w_r from "./images/WhiteRook.png";
import b_r from "./images/BlackRook.png";
import w_n from "./images/WhiteKnight.png";
import b_n from "./images/BlackKnight.png";
import w_q from "./images/WhiteQueen.png";
import b_q from "./images/BlackQueen.png";
import w_k from "./images/WhiteKing.png";
import b_k from "./images/BlackKing.png";

// Piece letter, piece color
const pieceImages = {
  p: {
    b: b_p,
    w: w_p,
  },
  b: {
    b: b_b,
    w: w_b,
  },
  r: {
    b: b_r,
    w: w_r,
  },
  n: {
    b: b_n,
    w: w_n,
  },
  q: {
    b: b_q,
    w: w_q,
  },
  k: {
    b: b_k,
    w: w_k,
  },
};

export default pieceImages;
