import "./App.css";
import Board from "./components/Board";
import Modal from "./other/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import Home from "./components/Home";

function App() {
  const [show, setShow] = useState(false);
  const [winner, setWinner] = useState("n");
  const [playGame, setPlayGame] = useState(false);
  return (
    <div>
      <div className="no-play">
        <h4>Sorry, I'm too lazy to make this game responsive</h4>
        <h6>Try playing on a larger device</h6>
      </div>
      {playGame ? (
        <div className="App">
          <Modal show={show} setShow={setShow} winner={winner} />
          <div style={{ width: "fit-content" }}>
            <Board
              show={show}
              setShow={setShow}
              winner={winner}
              setWinner={setWinner}
            />
          </div>
        </div>
      ) : (
        <Home setPlayGame={setPlayGame} />
      )}
    </div>
  );
}

export default App;
