let diagonal = [
  [-1, -1],
  [-2, -2],
  [-3, -3],
  [-4, -4],
  [-5, -5],
  [-6, -6],
  [-7, -7],
  [1, 1],
  [2, 2],
  [3, 3],
  [4, 4],
  [5, 5],
  [6, 6],
  [7, 7],
  [1, -1],
  [2, -2],
  [3, -3],
  [4, -4],
  [5, -5],
  [6, -6],
  [7, -7],
  [-1, 1],
  [-2, 2],
  [-3, 3],
  [-4, 4],
  [-5, 5],
  [-6, 6],
  [-7, 7],
];

let straight = [
  [0, -1],
  [0, -2],
  [0, -3],
  [0, -4],
  [0, -5],
  [0, -6],
  [0, -7],
  [0, 1],
  [0, 2],
  [0, 3],
  [0, 4],
  [0, 5],
  [0, 6],
  [0, 7],
  [1, 0],
  [2, 0],
  [3, 0],
  [4, 0],
  [5, 0],
  [6, 0],
  [7, 0],
  [-1, 0],
  [-2, 0],
  [-3, 0],
  [-4, 0],
  [-5, 0],
  [-6, 0],
  [-7, 0],
];

let surrounding = [
  [-1, -1],
  [0, -1],
  [1, -1],
  [-1, 0],
  [1, 0],
  [-1, 1],
  [0, 1],
  [1, 1],
];

let knight = [
  [-1, -2],
  [1, -2],
  [-2, -1],
  [-2, 1],
  [1, 2],
  [-1, 2],
  [2, 1],
  [2, -1],
];

let moveList = {
  p: {
    w: [
      [0, -1],
      [0, 1],
      [-1, 0],
      [1, 0],
    ],
    b: [
      [0, -1],
      [0, 1],
      [-1, 0],
      [1, 0],
    ],
  },
  b: {
    w: diagonal,
    b: diagonal,
  },
  n: {
    w: straight,
    b: straight,
  },
  r: {
    w: [],
    b: [],
  },
  q: {
    w: [...surrounding, ...diagonal, ...straight],
    b: [...surrounding, ...diagonal, ...straight],
  },
  k: {
    w: surrounding,
    b: surrounding,
  },
};

export default moveList;
