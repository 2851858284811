let gameArray = [
  ["bq", "bb", "bn", "bn", "bn", "bn", "bb", "bk"],
  ["br", "br", "bp", "bp", "bp", "bp", "br", "br"],
  ["bp", "bp", " ", " ", " ", " ", "bp", "bp"],
  [" ", " ", " ", " ", " ", " ", " ", " "],
  [" ", " ", " ", " ", " ", " ", " ", " "],
  ["wp", "wp", " ", " ", " ", " ", "wp", "wp"],
  ["wr", "wr", "wp", "wp", "wp", "wp", "wr", "wr"],
  ["wq", "wb", "wn", "wn", "wn", "wn", "wb", "wk"],
];

export default gameArray;
