import React, { useState, useEffect } from "react";
import Piece from "./Piece";
import Spot from "./Spot";
import useMousePosition from "./useMousePosition";
import piecesArray from "./piecesArray";
import gameArray from "./gameArray";
import moveList from "./moveList";
import ModalF from "../other/Modal";

const TILE_SIZE = 80;
const BOARD_COORDS = [50, 50];

function Board({ setShow, setWinner }) {
  const [gameStateArray, setGameStateArray] = useState(gameArray);
  const [possibleMoves, setPossibleMoves] = useState([]);

  const [rookOneHits, setRookOneHits] = useState(0);
  const [rookTwoHits, setRookTwoHits] = useState(0);
  const [rookThreeHits, setRookThreeHits] = useState(0);
  const [rookFourHits, setRookFourHits] = useState(0);
  const [rookFiveHits, setRookFiveHits] = useState(0);
  const [rookSixHits, setRookSixHits] = useState(0);
  const [rookSevenHits, setRookSevenHits] = useState(0);
  const [rookEightHits, setRookEightHits] = useState(0);

  const [turn, setTurn] = useState("n");
  const [castleStatus, setCastleStatus] = useState([
    [null, null, null],
    [null, null, null],
  ]);

  useEffect(() => {
    if (Math.floor(Math.random() * 2)) {
      setTurn("w");
    } else {
      setTurn("b");
    }
  }, []);

  const { x, y } = useMousePosition();

  function resetGame() {
    window.location.reload();
  }

  useEffect(() => {
    console.log({ gameStateArray });
  }, [gameStateArray]);

  function updateRookGameState(newRow, newCol, lastRow, lastCol, thisPiece) {
    setGameStateArray((prevArray) => {
      let newArray = [...prevArray];

      newArray[newRow][newCol] = thisPiece;

      if (thisPiece[1] === "p" && (newRow === 7 || newRow === 0)) {
        newArray[newRow][newCol] = `${thisPiece}`;
      }

      newArray[lastRow][lastCol] = " ";
      return newArray;
    });
    changeTurn();
    return true;
  }

  function causesJump(lastC, lastR, newC, newR, pieceType) {
    // Assume xDif and yDif are equal
    let xDif = newC - lastC;
    let yDif = newR - lastR;

    if (Math.abs(xDif) > 1 && Math.abs(yDif) > 1) {
      // get the spots inbetween and make sure they are all empty
      for (let d = 1; d < Math.abs(xDif); d++) {
        if (
          gameStateArray[lastR + d * (yDif < 0 ? -1 : 1)][
            lastC + d * (xDif < 0 ? -1 : 1)
          ] !== " "
        ) {
          return true;
        }
      }
    } else if (Math.abs(xDif) > 1 && yDif === 0) {
      let direction = xDif < 0 ? -1 : 1;

      for (let d = 1; d < Math.abs(xDif); d++) {
        if (gameStateArray[lastR][lastC + d * direction] !== " ") {
          return true;
        }
      }
    } else if (Math.abs(yDif) > 1 && xDif === 0) {
      for (let d = 1; d < Math.abs(yDif); d++) {
        let direction = yDif < 0 ? -1 : 1;
        if (gameStateArray[lastR + d * direction][lastC] !== " ") {
          return true;
        }
      }
    }
  }

  function validMove(lastC, lastR, newC, newR, pieceColor, pieceType) {
    // Don't repeat the same move
    if (lastC === newC && lastR === newR) {
      return false;
    }

    // Make sure it is within bounds
    if (lastR < 0 || lastR > 7 || lastC < 0 || lastC > 7) {
      return false;
    }

    let thisPiece = `${gameStateArray[lastR][lastC]}`;
    console.log({ thisPiece, possibleMoves });

    for (let i = 0, l = possibleMoves.length; i < l; i++) {
      if (possibleMoves[i][0] === newC && possibleMoves[i][1] === newR) {
        if (causesJump(lastC, lastR, newC, newR)) {
          return false;
        }

        if (gameStateArray[newR][newC][1] === "r") {
          console.log({ newR, newC });
          if (newR === 1 && newC === 7) {
            if (rookOneHits + 1 === 3) {
              return updateRookGameState(newR, newC, lastR, lastC, thisPiece);
            } else {
              setRookOneHits(rookOneHits + 1);
              changeTurn();
              return false;
            }
          } else if (newR === 1 && newC === 6) {
            if (rookTwoHits + 1 === 3) {
              return updateRookGameState(newR, newC, lastR, lastC, thisPiece);
            } else {
              setRookTwoHits(rookTwoHits + 1);
              changeTurn();
              return false;
            }
          } else if (newR === 1 && newC === 1) {
            if (rookThreeHits + 1 === 3) {
              return updateRookGameState(newR, newC, lastR, lastC, thisPiece);
            } else {
              setRookThreeHits(rookThreeHits + 1);
              changeTurn();
              return false;
            }
          } else if (newR === 1 && newC === 0) {
            if (rookFourHits + 1 === 3) {
              return updateRookGameState(newR, newC, lastR, lastC, thisPiece);
            } else {
              setRookFourHits(rookFourHits + 1);
              changeTurn();
              return false;
            }
          } else if (newR === 6 && newC === 0) {
            if (rookFiveHits + 1 === 3) {
              return updateRookGameState(newR, newC, lastR, lastC, thisPiece);
            } else {
              setRookFiveHits(rookFiveHits + 1);
              changeTurn();
              return false;
            }
          } else if (newR === 6 && newC === 1) {
            if (rookSixHits + 1 === 3) {
              return updateRookGameState(newR, newC, lastR, lastC, thisPiece);
            } else {
              setRookSixHits(rookSixHits + 1);
              changeTurn();
              return false;
            }
          } else if (newR === 6 && newC === 6) {
            if (rookSevenHits + 1 === 3) {
              return updateRookGameState(newR, newC, lastR, lastC, thisPiece);
            } else {
              setRookSevenHits(rookSevenHits + 1);
              changeTurn();
              return false;
            }
          } else if (newR === 6 && newC === 7) {
            if (rookEightHits + 1 === 3) {
              return updateRookGameState(newR, newC, lastR, lastC, thisPiece);
            } else {
              setRookEightHits(rookEightHits + 1);
              changeTurn();
              return false;
            }
          }
          return false;
        }

        setGameStateArray((prevArray) => {
          let newArray = [...prevArray];
          let spotTaken = `${prevArray[newR][newC]}`;

          if (
            (spotTaken === "wk" && thisPiece !== "wk") ||
            (spotTaken === "wq" && thisPiece !== "wq")
          ) {
            setShow(true);
            setWinner("b");
            setTurn("");
          } else if (
            (spotTaken === "bk" && thisPiece !== "bk") ||
            (spotTaken === "bq" && thisPiece !== "bq")
          ) {
            setShow(true);
            setWinner("w");
            setTurn("");
          }

          newArray[newR][newC] = thisPiece;

          if (thisPiece[1] === "p" && (newR === 7 || newR === 0)) {
            newArray[newR][newC] = `${thisPiece}`;
          }

          newArray[lastR][lastC] = " ";
          return newArray;
        });
        return true;
      }
    }
  }

  // Clear the possible moves when no pieces are selected
  function clearPossibleMoves() {
    setPossibleMoves([]);
  }

  function changeTurn() {
    if (turn === "w") {
      setTurn("b");
    } else {
      setTurn("w");
    }
  }

  function savePossibleMoves(currentC, currentR, pieceType, pieceColor) {
    // Filter out the obvious impossible moves
    let availableMoves = moveList[pieceType][pieceColor].map((move, i) => {
      let possibleCol = currentC + move[0];
      let possibleRow = currentR + move[1];

      // If this move is within the board boundaries
      if (
        possibleRow >= 0 &&
        possibleRow <= 7 &&
        possibleCol >= 0 &&
        possibleCol <= 7
      ) {
        let spotPiece = gameStateArray[possibleRow][possibleCol];

        // If this move is the opposite color
        if (spotPiece[0] !== pieceColor) {
          return [possibleCol, possibleRow];
        }
      }
      return [];
    });

    setPossibleMoves([[currentC, currentR], ...availableMoves]);
  }

  //Initialize the pieces array
  const pieces = piecesArray.map((spot, i) => {
    return (
      <Piece
        key={i}
        startX={BOARD_COORDS[0] + spot[1] * TILE_SIZE}
        startY={BOARD_COORDS[1] + spot[0] * TILE_SIZE}
        pieceType={spot[2][1]}
        pieceColor={spot[2][0]}
        mouseX={x}
        mouseY={y}
        tileSize={TILE_SIZE}
        boardCoords={BOARD_COORDS}
        validMove={validMove}
        gameStateArray={gameStateArray}
        clearPossibleMoves={clearPossibleMoves}
        savePossibleMoves={savePossibleMoves}
        changeTurn={changeTurn}
        turn={turn}
        castleStatus={castleStatus}
        setCastleStatus={setCastleStatus}
      />
    );
  });

  // Initialize the spots array
  const spots = gameArray.map((row, r) => {
    return row.map((col, c) => {
      return (
        <Spot
          key={r + c}
          startX={BOARD_COORDS[0] + c * TILE_SIZE}
          startY={BOARD_COORDS[1] + r * TILE_SIZE}
          tileSize={TILE_SIZE}
          spotColor={(r + c) % 2 ? "#b48766" : "#f0d9b7"}
          possibleMoves={possibleMoves}
          col={c}
          row={r}
          causesJump={causesJump}
          gameStateArray={gameStateArray}
        />
      );
    });
  });

  const styles = {
    width: TILE_SIZE * 8,
    height: TILE_SIZE * 8,
    top: BOARD_COORDS[1],
    left: BOARD_COORDS[0],
  };

  return (
    <div className="chessBoard" style={styles}>
      {pieces}
      {spots}
      <p
        style={{
          position: "absolute",
          top: "100%",
          fontFamily: "Permanent Marker",
        }}
      >
        {turn === "w" ? "White's turn" : "Black's turn"}
      </p>
      <button className="resetButton" onClick={resetGame}>
        New game
      </button>
    </div>
  );
}

export default Board;
